// src/components/Messaging/MessageDetail.tsx
import React, { useEffect, useState } from "react";
import axios from "../../api";
import { useParams } from "react-router-dom";
import styles from "./MessageDetail.module.css";

interface Message {
  id: string;
  senderName: string;
  content: string;
  timestamp: string;
}

const MessageDetail: React.FC = () => {
  const { conversationId } = useParams<{ conversationId: string }>();
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState("");

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get(`/messages/${conversationId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        setMessages(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchMessages();
  }, [conversationId]);

  const handleSend = async () => {
    try {
      await axios.post(
        `/messages/${conversationId}`,
        { content: newMessage },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setNewMessage("");
      // Refresh messages
      const response = await axios.get(`/messages/${conversationId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setMessages(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.messageDetail}>
      <h2>Conversation</h2>
      <div className={styles.messageList}>
        {messages.map((msg) => (
          <div key={msg.id} className={styles.messageItem}>
            <div className={styles.senderName}>{msg.senderName}</div>
            <div className={styles.messageContent}>{msg.content}</div>
            <div className={styles.timestamp}>
              {new Date(msg.timestamp).toLocaleString()}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.messageInput}>
        <textarea
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          rows={3}
        />
        <button onClick={handleSend}>Send</button>
      </div>
    </div>
  );
};

export default MessageDetail;
