import { useAuthContext } from "../../contexts/AuthContext";
import BrandProfile from "./BrandProfile";
import ProducerProfile from "./ProducerProfile";

const Profile: React.FC = () => {
  const authContext = useAuthContext();

  if (!authContext || authContext.loading) {
    return <div>Loading...</div>;
  }

  const { user } = authContext;

  if (!user) {
    return <div>User not authenticated.</div>;
  }

  return user.role === "brand" ? <BrandProfile /> : <ProducerProfile />;
};

export default Profile;
