import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Avatar,
  CircularProgress,
} from "@mui/material";
import { BusinessCenter, Notifications, Storefront } from "@mui/icons-material";
import { useAuthContext } from "../../contexts/AuthContext";
import axios from "../../api";
import { INotification } from "../../types/Notification";
import { getProductPlacementOfferings } from "../../services/productService";
import PlacementOfferingsList from "../Products/PlacementOfferingList";

interface Product {
  _id: string;
  productName: string;
  productDescription: string;
  productImageUrl?: string;
}

interface PlacementOffering {
  _id: string;
  productId: string;
  offeringTitle: string;
  offeringDescription: string;
  matchCriteria: string;
  otherDetails?: string;
}

const BrandDashboard: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const { user } = useAuthContext();

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const [productsRes, notificationsRes] = await Promise.all([
          axios.get("/products"),
          axios.get("/notifications"),
        ]);

        setProducts(productsRes.data);
        setNotifications(notificationsRes.data);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  if (!user) {
    return (
      <Container sx={{ textAlign: "center", marginTop: 4 }}>
        <CircularProgress color="secondary" />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ marginTop: 4 }}>
      {/* Welcome Message */}
      <Typography variant="h4" gutterBottom color="textPrimary">
        Welcome back, {user.name}!
      </Typography>

      {/* Products */}
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 4, mb: 2 }}
      >
        <Typography variant="h5" color="textPrimary">
          <Storefront /> Your Products
        </Typography>
        <Button variant="contained" color="secondary" href="/products/new">
          Add New Product
        </Button>
      </Grid>
      {loading ? (
        <CircularProgress color="secondary" />
      ) : products.length > 0 ? (
        products.map((product) => (
          <Card
            key={product._id}
            sx={{ marginBottom: 4, backgroundColor: "background.paper" }}
          >
            <CardContent>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Avatar
                    alt={product.productName}
                    src={
                      product.productImageUrl || "/default-product-image.png"
                    }
                    sx={{ width: 60, height: 60 }}
                  />
                </Grid>
                <Grid item xs>
                  <Typography variant="h6" color="textPrimary">
                    {product.productName}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {product.productDescription}
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    href={`/products/${product._id}`}
                  >
                    View Product Details
                  </Button>
                </Grid>
              </Grid>
            </CardContent>

            {/* Placement Offerings for this Product */}
            <CardContent>
              <Typography variant="h6" gutterBottom color="textPrimary">
                Placement Offerings
              </Typography>
              <PlacementOfferingsList productId={product._id} />
              <Button
                variant="contained"
                color="secondary"
                href={`/products/${product._id}/placement-offerings/new`}
                sx={{ marginTop: 2 }}
              >
                Add Placement Offering
              </Button>
            </CardContent>
          </Card>
        ))
      ) : (
        <Typography color="textSecondary" sx={{ marginTop: 2 }}>
          You have no products.
        </Typography>
      )}
    </Container>
  );
};

export default BrandDashboard;
