// src/components/Projects/ProjectForm.tsx
import React, { useState } from "react";
import { Container, Typography, Button, Box } from "@mui/material";
import axios from "../../api";
import { useNavigate } from "react-router-dom";
import ProjectTypeSelector from "./ProjectTypeSelector";
import CommonFields from "./CommonFields";
import VideoProductionFields from "./VideoProductionFields";
import VideoGameFields from "./VideoGameFields";
import api from "../../api";

const ProjectForm: React.FC = () => {
  const navigate = useNavigate();

  // State Management
  const [projectType, setProjectType] = useState<string>("Video Production");

  // Common Fields
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedGenres, setSelectedGenres] = useState<string[]>([]);
  const [budget, setBudget] = useState<number | "">("");
  const [productionCompany, setProductionCompany] = useState("");
  const [releaseDate, setReleaseDate] = useState<Date | null>(null);

  // Video Production Fields
  const [shootingLocations, setShootingLocations] = useState<string[]>([]);
  const [duration, setDuration] = useState("");
  const [format, setFormat] = useState("");
  const [projectPoster, setProjectPoster] = useState<File | null>(null);
  const [trailerURL, setTrailerURL] = useState("");
  const [additionalMediaLinks, setAdditionalMediaLinks] = useState<string[]>([
    "",
  ]);

  // Video Game Fields
  const [platformsSelected, setPlatformsSelected] = useState<string[]>([]);

  // Submit Handler
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const formData = new FormData();

    // Common Fields
    formData.append("title", title);
    formData.append("description", description);
    selectedGenres.forEach((genre) => formData.append("genres", genre));
    if (budget !== "") formData.append("budget", budget.toString());
    formData.append("productionCompany", productionCompany);
    if (releaseDate) formData.append("releaseDate", releaseDate.toISOString());

    // Project Type
    formData.append("projectType", projectType);

    if (projectType === "Video Production") {
      // Video Production Specific Fields
      shootingLocations.forEach((location) =>
        formData.append("shootingLocations", location)
      );
      formData.append("duration", duration);
      formData.append("format", format);
      if (projectPoster) formData.append("projectPoster", projectPoster);
      if (trailerURL) formData.append("trailerURL", trailerURL);
      additionalMediaLinks.forEach((link) => {
        if (link.trim() !== "") formData.append("additionalMediaLinks", link);
      });
    } else if (projectType === "Video Game") {
      // Video Game Specific Fields
      platformsSelected.forEach((platform) =>
        formData.append("platforms", platform)
      );
    }

    try {
      await api.post("/projects", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      navigate("/dashboard");
    } catch (error: any) {
      console.error(error);
      alert("An error occurred while creating the project.");
    }
  };

  return (
    <Container maxWidth="md" sx={{ marginTop: 4, marginBottom: 4 }}>
      <Typography variant="h4" gutterBottom>
        Create New Project
      </Typography>
      <form onSubmit={handleSubmit}>
        {/* Project Type Selector */}
        <ProjectTypeSelector
          projectType={projectType}
          setProjectType={setProjectType}
        />

        {/* Common Fields */}
        <CommonFields
          title={title}
          setTitle={setTitle}
          description={description}
          setDescription={setDescription}
          budget={budget}
          setBudget={setBudget}
          productionCompany={productionCompany}
          setProductionCompany={setProductionCompany}
          releaseDate={releaseDate}
          setReleaseDate={setReleaseDate}
          projectPoster={projectPoster}
          setProjectPoster={setProjectPoster}
        />

        {/* Conditional Fields */}
        {projectType === "Video Production" ? (
          <VideoProductionFields
            selectedGenres={selectedGenres}
            setSelectedGenres={setSelectedGenres}
            shootingLocations={shootingLocations}
            setShootingLocations={setShootingLocations}
            duration={duration}
            setDuration={setDuration}
            format={format}
            setFormat={setFormat}
            trailerURL={trailerURL}
            setTrailerURL={setTrailerURL}
            additionalMediaLinks={additionalMediaLinks}
            setAdditionalMediaLinks={setAdditionalMediaLinks}
          />
        ) : (
          <VideoGameFields
            selectedGenres={selectedGenres}
            setSelectedGenres={setSelectedGenres}
            platformsSelected={platformsSelected}
            setPlatformsSelected={setPlatformsSelected}
          />
        )}

        {/* Submit Button */}
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          fullWidth
          sx={{ marginTop: 4 }}
        >
          Create Project
        </Button>
      </form>
    </Container>
  );
};

export default ProjectForm;
