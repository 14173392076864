import api from "../api";
import { TPlacementOpportunityPayload } from "../types/Project";

export const postPlacementOpportunity = async (
  projectId: string,
  placementOpportunity: TPlacementOpportunityPayload
) => {
  try {
    const response = await api.post(
      `/projects/${projectId}/placement-opportunities`,
      placementOpportunity
    );
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw error;
  }
};

export const getPlacementOpportunitiesByProjectId = async (
  projectId: string
) => {
  try {
    const response = await api.get(
      `/projects/${projectId}/placement-opportunities`
    );
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw error;
  }
};
