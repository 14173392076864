// src/components/Projects/VideoProductionFields.tsx
import React from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Chip,
} from "@mui/material";
import ChipInput from "../Common/ChipInput";

const formats = [
  "Theatrical Release",
  "Streaming",
  "Television",
  "Social Media",
];

const genres = [
  "Action",
  "Comedy",
  "Drama",
  "Fantasy",
  "Horror",
  "Romance",
  "Sci-Fi",
  "Thriller",
];

interface VideoProductionFieldsProps {
  selectedGenres: string[];
  setSelectedGenres: (genres: string[]) => void;
  shootingLocations: string[];
  setShootingLocations: (locations: string[]) => void;
  duration: string;
  setDuration: (value: string) => void;
  format: string;
  setFormat: (value: string) => void;
  trailerURL: string;
  setTrailerURL: (value: string) => void;
  additionalMediaLinks: string[];
  setAdditionalMediaLinks: (links: string[]) => void;
}

const VideoProductionFields: React.FC<VideoProductionFieldsProps> = ({
  selectedGenres,
  setSelectedGenres,
  shootingLocations,
  setShootingLocations,
  duration,
  setDuration,
  format,
  setFormat,
  trailerURL,
  setTrailerURL,
  additionalMediaLinks,
  setAdditionalMediaLinks,
}) => {
  // Handlers for Additional Media Links
  const handleAddMediaLink = () => {
    setAdditionalMediaLinks([...additionalMediaLinks, ""]);
  };

  const handleMediaLinkChange = (index: number, value: string) => {
    const newLinks = [...additionalMediaLinks];
    newLinks[index] = value;
    setAdditionalMediaLinks(newLinks);
  };

  const handleRemoveMediaLink = (index: number) => {
    const newLinks = [...additionalMediaLinks];
    newLinks.splice(index, 1);
    setAdditionalMediaLinks(newLinks);
  };

  return (
    <>
      {/* Genres */}
      <FormControl fullWidth margin="normal">
        <InputLabel id="genres-label">Genres</InputLabel>
        <Select
          labelId="genres-label"
          id="genres"
          multiple
          value={selectedGenres}
          onChange={(e) => setSelectedGenres(e.target.value as string[])}
          input={<OutlinedInput label="Genres" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {(selected as string[]).map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
        >
          {genres.map((genre) => (
            <MenuItem key={genre} value={genre}>
              <Checkbox checked={selectedGenres.indexOf(genre) > -1} />
              <ListItemText primary={genre} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box sx={{ mt: 2 }}>
        <Typography variant="h6">Shooting Location(s)</Typography>
        <ChipInput
          value={shootingLocations}
          onAdd={(location) =>
            setShootingLocations([...shootingLocations, location])
          }
          onDelete={(location, index) => {
            const newLocations = [...shootingLocations];
            newLocations.splice(index, 1);
            setShootingLocations(newLocations);
          }}
          placeholder="Add shooting location and press Enter"
        />
      </Box>

      {/* Duration */}
      <TextField
        label="Duration"
        value={duration}
        onChange={(e) => setDuration(e.target.value)}
        required
        fullWidth
        margin="normal"
        placeholder="e.g., 120 minutes, 10 episodes"
      />

      {/* Format */}
      <FormControl fullWidth margin="normal">
        <InputLabel id="format-label">Format</InputLabel>
        <Select
          labelId="format-label"
          id="format"
          value={format}
          onChange={(e) => setFormat(e.target.value as string)}
          label="Format"
          required
        >
          {formats.map((fmt) => (
            <MenuItem key={fmt} value={fmt}>
              {fmt}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Trailer URL (Optional) */}
      <TextField
        label="Trailer/Teaser URL (Optional)"
        type="url"
        value={trailerURL}
        onChange={(e) => setTrailerURL(e.target.value)}
        fullWidth
        margin="normal"
      />
    </>
  );
};

export default VideoProductionFields;
