// src/components/PlacementOfferings/PlacementOfferingsList.tsx
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Chip,
  CircularProgress,
  CardActions,
  Button,
} from "@mui/material";
import axios from "../../api"; // Ensure axios is correctly configured
import { get } from "http";
import { getProductPlacementOfferings } from "../../services/productService";

interface PlacementOffering {
  _id: string;
  offeringTitle: string;
  offeringDescription: string;
  matchCriteria: string;
  otherDetails?: string;
  tags?: string[];
}

interface PlacementOfferingsListProps {
  productId: string;
}

const PlacementOfferingsList: React.FC<PlacementOfferingsListProps> = ({
  productId,
}) => {
  const [placementOfferings, setPlacementOfferings] = useState<
    PlacementOffering[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchPlacementOfferings = async () => {
      try {
        const placementOfferings = await getProductPlacementOfferings(
          productId
        );
        setPlacementOfferings(placementOfferings);
      } catch (error: any) {
        console.error("Error fetching placement offerings:", error);
        alert("Failed to fetch placement offerings.");
      } finally {
        setLoading(false);
      }
    };

    fetchPlacementOfferings();
  }, [productId]);

  if (loading) {
    return (
      <Box sx={{ mt: 4, textAlign: "center" }}>
        <CircularProgress color="secondary" />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Loading Placement Offerings...
        </Typography>
      </Box>
    );
  }

  if (placementOfferings.length === 0) {
    return (
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">No Placement Offerings Found.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h5" gutterBottom>
        Placement Offerings
      </Typography>
      <Grid container spacing={2}>
        {placementOfferings.map((offering) => (
          <Grid item xs={12} key={offering._id}>
            <Card>
              <CardContent>
                <Typography variant="h6">{offering.offeringTitle}</Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  {offering.offeringDescription}
                </Typography>

                <Box sx={{ mt: 2 }}>
                  <Typography variant="body2">
                    <strong>Match Criteria:</strong> {offering.matchCriteria}
                  </Typography>
                  {offering.otherDetails && (
                    <Typography variant="body2">
                      <strong>Other Details:</strong> {offering.otherDetails}
                    </Typography>
                  )}
                  {offering.tags && offering.tags.length > 0 && (
                    <Box sx={{ mt: 1 }}>
                      {offering.tags.map((tag) => (
                        <Chip label={tag} key={tag} sx={{ mr: 1, mt: 1 }} />
                      ))}
                    </Box>
                  )}
                </Box>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  color="secondary"
                  href={`/products/${productId}/placement-offerings/${offering._id}`}
                >
                  View
                </Button>
                <Button
                  size="small"
                  color="secondary"
                  href={`/products/${productId}/placement-offerings/${offering._id}/edit`}
                >
                  Edit
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PlacementOfferingsList;
