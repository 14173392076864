// src/components/Profiles/BrandProfile.tsx

import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Avatar,
  CircularProgress,
  Alert,
  Grid,
  IconButton,
  Tabs,
  Tab,
  AppBar,
  InputAdornment,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  PhotoCamera,
  Facebook,
  Twitter,
  Instagram,
  LinkedIn,
  Language,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import axios from "../../api";
import { useAuthContext } from "../../contexts/AuthContext";

interface BrandProfileData {
  name: string;
  description?: string;
  website?: string;
  contactEmail?: string;
  contactPhone?: string;
  socialMedia?: {
    facebook?: string;
    twitter?: string;
    instagram?: string;
    linkedIn?: string;
  };
  logoUrl?: string;
  address?: string;
  industry?: string;
  missionStatement?: string;
  targetAudience?: string;
}

const BrandProfile: React.FC = () => {
  const [profile, setProfile] = useState<BrandProfileData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [tabIndex, setTabIndex] = useState<number>(0);

  // New state variables for password change
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const { logout } = useAuthContext();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get("/users/me");
        setProfile(response.data);
      } catch (error) {
        console.error(error);
        setErrorMessage("Failed to fetch profile information.");
      } finally {
        setLoading(false);
      }
    };
    fetchProfile();
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setProfile({ ...profile!, [e.target.name]: e.target.value });
  };

  const handleSocialMediaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProfile({
      ...profile!,
      socialMedia: {
        ...profile?.socialMedia,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleLogoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setLogoFile(e.target.files[0]);
      // Optionally, you can display a preview or upload the file immediately
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      let updatedProfile = { ...profile };

      // Handle logo upload if a new file is selected
      if (logoFile) {
        const formData = new FormData();
        formData.append("file", logoFile);

        const uploadResponse = await axios.post("/upload/logo", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        updatedProfile.logoUrl = uploadResponse.data.url;
      }

      await axios.put("/users/me", updatedProfile);
      setSuccessMessage("Profile updated successfully.");
      setErrorMessage("");
    } catch (error) {
      console.error(error);
      setErrorMessage("An error occurred while updating your profile.");
      setSuccessMessage("");
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    // Clear success and error messages when switching tabs
    setSuccessMessage("");
    setErrorMessage("");
  };

  // Password change handler
  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      setErrorMessage("New passwords do not match.");
      return;
    }
    try {
      await axios.put("/auth/change-password", {
        currentPassword,
        newPassword,
      });
      setSuccessMessage("Password changed successfully.");
      setErrorMessage("");
      // Clear password fields
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error: any) {
      console.error(error);
      setErrorMessage(
        error.response?.data?.message || "Error changing password."
      );
      setSuccessMessage("");
    }
  };

  // Toggle password visibility
  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  if (loading) {
    return (
      <Container sx={{ textAlign: "center", mt: 4 }}>
        <CircularProgress color="secondary" />
      </Container>
    );
  }

  if (!profile) {
    return (
      <Container sx={{ textAlign: "center", mt: 4 }}>
        <Typography variant="h6" color="error">
          {errorMessage || "Profile data not available."}
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Box
        sx={{
          p: 3,
          backgroundColor: "background.paper",
          borderRadius: 2,
        }}
      >
        <Typography variant="h4" gutterBottom>
          Your Profile
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
          <Avatar
            alt={profile.name}
            src={
              logoFile
                ? URL.createObjectURL(logoFile)
                : profile.logoUrl || "/default-logo.png"
            }
            sx={{ width: 80, height: 80, mr: 2 }}
          />
          <label htmlFor="logo-upload">
            <input
              accept="image/*"
              id="logo-upload"
              type="file"
              style={{ display: "none" }}
              onChange={handleLogoUpload}
            />
            <IconButton color="primary" component="span">
              <PhotoCamera />
            </IconButton>
          </label>
        </Box>

        {successMessage && (
          <Alert severity="success" sx={{ mb: 2 }}>
            {successMessage}
          </Alert>
        )}
        {errorMessage && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {errorMessage}
          </Alert>
        )}

        <AppBar position="static" color="default">
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant={isSmallScreen ? "scrollable" : "standard"}
            scrollButtons="auto"
          >
            <Tab label="General Info" />
            <Tab label="Contact Info" />
            <Tab label="Social Media" />
            <Tab label="Security" />
          </Tabs>
        </AppBar>

        <form onSubmit={handleSubmit}>
          {tabIndex === 0 && (
            <Box sx={{ mt: 3 }}>
              <TextField
                label="Brand Name"
                name="name"
                value={profile.name || ""}
                onChange={handleChange}
                required
                fullWidth
                margin="normal"
              />
              <TextField
                label="Description"
                name="description"
                value={profile.description || ""}
                onChange={handleChange}
                multiline
                rows={4}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Mission Statement"
                name="missionStatement"
                value={profile.missionStatement || ""}
                onChange={handleChange}
                multiline
                rows={2}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Industry"
                name="industry"
                value={profile.industry || ""}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Box>
          )}

          {tabIndex === 1 && (
            <Box sx={{ mt: 3 }}>
              <TextField
                label="Website"
                name="website"
                value={profile.website || ""}
                onChange={handleChange}
                fullWidth
                margin="normal"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Language />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Contact Email"
                name="contactEmail"
                type="email"
                value={profile.contactEmail || ""}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Contact Phone"
                name="contactPhone"
                value={profile.contactPhone || ""}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Address"
                name="address"
                value={profile.address || ""}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Box>
          )}

          {tabIndex === 2 && (
            <Box sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Facebook"
                    name="facebook"
                    value={profile.socialMedia?.facebook || ""}
                    onChange={handleSocialMediaChange}
                    fullWidth
                    margin="normal"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Facebook color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Twitter"
                    name="twitter"
                    value={profile.socialMedia?.twitter || ""}
                    onChange={handleSocialMediaChange}
                    fullWidth
                    margin="normal"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Twitter color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Instagram"
                    name="instagram"
                    value={profile.socialMedia?.instagram || ""}
                    onChange={handleSocialMediaChange}
                    fullWidth
                    margin="normal"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Instagram color="secondary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="LinkedIn"
                    name="linkedIn"
                    value={profile.socialMedia?.linkedIn || ""}
                    onChange={handleSocialMediaChange}
                    fullWidth
                    margin="normal"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LinkedIn color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          )}

          {tabIndex === 3 && (
            <Box sx={{ mt: 3 }}>
              <Typography variant="h6" gutterBottom>
                Change Password
              </Typography>
              <TextField
                label="Current Password"
                name="currentPassword"
                type={showPassword ? "text" : "password"}
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                required
                fullWidth
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="New Password"
                name="newPassword"
                type={showPassword ? "text" : "password"}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                fullWidth
                margin="normal"
                helperText="Password must be at least 8 characters."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Confirm New Password"
                name="confirmPassword"
                type={showPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                fullWidth
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {/* Change Password Button */}
              <Button
                type="button"
                variant="contained"
                color="secondary"
                fullWidth
                sx={{ mt: 3 }}
                onClick={handlePasswordChange}
              >
                Change Password
              </Button>
            </Box>
          )}

          {/* Save Changes Button (for profile updates) */}
          {tabIndex !== 3 && (
            <>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                fullWidth
                sx={{ mt: 3 }}
              >
                Save Changes
              </Button>
              <Button
                variant="contained"
                color="error"
                fullWidth
                onClick={logout}
                sx={{ mt: 1 }}
              >
                Log out
              </Button>
            </>
          )}
        </form>
      </Box>
    </Container>
  );
};

export default BrandProfile;
