// src/contexts/AuthContext.tsx
import React, {
  createContext,
  useState,
  useEffect,
  PropsWithChildren,
  useContext,
} from "react";
import api from "../api"; // Your Axios instance
import { UserProfile } from "../types/User";
import { getUserProfile } from "../services/userService";
import { postLogin } from "../services/authService";

interface AuthContextType {
  user: UserProfile | null;
  loading: boolean;
  error: string | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Function to fetch user profile
  const fetchUserProfile = async () => {
    try {
      setLoading(true);
      const user = await getUserProfile();
      setUser(user);
      setError(null);
    } catch (err: any) {
      setUser(null);
      if (err.response && err.response.status === 401) {
        localStorage.removeItem("token");
      } else {
        setError("Failed to fetch user profile.");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      fetchUserProfile();
    } else {
      setLoading(false);
    }
  }, []);

  const login = async (email: string, password: string) => {
    try {
      const token = await postLogin(email, password);
      localStorage.setItem("token", token);
      await fetchUserProfile();
    } catch (err) {
      throw err;
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, loading, error, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }
  return context;
};
