import api from "../api";

export const postLogin = async (email: string, password: string) => {
  try {
    const response = await api.post("/auth/login", { email, password });
    return response.data.token;
  } catch (error: any) {
    throw error;
  }
};
