import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Avatar,
  Grid,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import axios from "../../api";
import { Product } from "../../types/Product";
import {
  deleteProductById,
  getProductById,
} from "../../services/productService";
import PlacementOfferingList from "./PlacementOfferingList";

const ProductDetail: React.FC = () => {
  const { productId } = useParams<{ productId: string }>();
  const navigate = useNavigate();

  const [product, setProduct] = useState<Product | null>(null);

  const [loading, setLoading] = useState<boolean>(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        if (!productId) return;
        const product = await getProductById(productId);
        setProduct(product);
      } catch (error) {
        console.error("Error fetching product details:", error);
        alert("An error occurred while fetching product details.");
      } finally {
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [productId]);

  const handleDeleteProduct = async () => {
    try {
      if (!productId) return;
      await deleteProductById(productId);
      navigate("/dashboard");
    } catch (error) {
      console.error("Error deleting product:", error);
      alert("An error occurred while deleting the product.");
    }
  };

  if (loading) {
    return (
      <Container sx={{ textAlign: "center", marginTop: 4 }}>
        <CircularProgress color="secondary" />
      </Container>
    );
  }

  if (!product) {
    return (
      <Container sx={{ textAlign: "center", marginTop: 4 }}>
        <Typography variant="h6" color="textSecondary">
          Product not found.
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ marginTop: 4 }}>
      {/* Product Details */}
      <Card sx={{ marginBottom: 4, backgroundColor: "background.paper" }}>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Avatar
                alt={product.productName}
                src={product.productImageUrl || "/default-product-image.png"}
                sx={{ width: 80, height: 80 }}
              />
            </Grid>
            <Grid item xs>
              <Typography variant="h5" color="textPrimary">
                {product.productName}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {product.productDescription}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate(`/products/${productId}/edit`)}
          >
            Edit Product
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => setDeleteDialogOpen(true)}
          >
            Delete Product
          </Button>
        </CardActions>
      </Card>

      {/* Placement Offerings */}
      {productId && <PlacementOfferingList productId={productId} />}

      {/* Add Placement Offering Button */}
      <Button
        variant="contained"
        color="secondary"
        onClick={() =>
          navigate(`/products/${productId}/placement-offerings/new`)
        }
        sx={{ marginTop: 2 }}
      >
        Add Placement Offering
      </Button>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this product and all its placement
            offerings?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDeleteProduct} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ProductDetail;
