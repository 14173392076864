import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Navbar from "./components/Common/Navbar";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import ProjectForm from "./components/Projects/ProjectForm";
import MessageList from "./components/Messaging/MessageList";
import MessageDetail from "./components/Messaging/MessageDetail";
import RequireAuth from "./components/Common/RequireAuth";
import { AuthProvider } from "./contexts/AuthContext";
import Dashboard from "./components/Dashboard/Dashboard";
import Profile from "./components/Profiles/Profile";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { CssBaseline } from "@mui/material";
import ProjectDetails from "./components/Projects/ProjectDetails";
import PlacementOpportunitiesForm from "./components/Projects/PlacementOpportunitiesForm";
import ProductForm from "./components/Products/ProductForm";
import ProductDetail from "./components/Products/ProductDetail";
import PlacementOfferingForm from "./components/Products/PlacementOfferingForm";
import PlacementOfferingEdit from "./components/Products/PlacementOfferingEdit";
import BrandPublicProfile from "./components/Profiles/BrandPublicProfile";
import ProducerPublicProfile from "./components/Profiles/ProducerPublicProfile";
import NotificationsList from "./components/Messaging/NotificationsList";

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Router>
          <Navbar />
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />

            <Route element={<RequireAuth />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/profile" element={<Profile />} />

              {/* Project routes */}
              <Route path="/projects/new" element={<ProjectForm />} />
              <Route path="/projects/:projectId" element={<ProjectDetails />} />
              <Route
                path="/projects/:projectId/edit"
                element={<ProjectDetails />}
              />
              <Route
                path="/projects/:projectId/add-placement-opportunity"
                element={<PlacementOpportunitiesForm />}
              />

              {/* Product routes */}
              <Route path="/products/new" element={<ProductForm />} />
              <Route path="/products/:productId" element={<ProductDetail />} />
              <Route
                path="/products/:productId/placement-offerings/new"
                element={<PlacementOfferingForm />}
              />
              <Route
                path="/products/:productId/placement-offerings/:offeringId/edit"
                element={<PlacementOfferingEdit />}
              />

              {/* Messaging routes */}
              <Route path="/messages" element={<MessageList />} />
              <Route path="/notifications" element={<NotificationsList />} />
              <Route
                path="/messages/:conversationId"
                element={<MessageDetail />}
              />
              <Route path="/brands/:brandId" element={<BrandPublicProfile />} />
              <Route
                path="/producers/:producerId"
                element={<ProducerPublicProfile />}
              />
            </Route>
          </Routes>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
