import React, { useEffect, useState } from "react";
import { Box, Typography, Card, CardContent, Grid, Chip } from "@mui/material";
import { getPlacementOpportunitiesByProjectId } from "../../services/placementOpportunitiesService";

interface PlacementOpportunity {
  _id: string;
  opportunityTitle: string;
  opportunityType: string;
  opportunityDescription: string;
  productRequirements: string;
  placementLocation: string;
  durationOfPlacement: string;
  visibilityLevel: string;
  compensationType: string;
  compensationAmount?: number;
  barterDetails?: string;
  numberOfPlacements: number;
  brandBenefits?: string;
  tags?: string[];
}

interface PlacementOpportunitiesListProps {
  projectId: string;
}

const PlacementOpportunitiesList: React.FC<PlacementOpportunitiesListProps> = ({
  projectId,
}) => {
  const [placementOpportunities, setPlacementOpportunities] = useState<
    PlacementOpportunity[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchPlacementOpportunities = async () => {
      try {
        const placementOpportunities =
          await getPlacementOpportunitiesByProjectId(projectId);
        setPlacementOpportunities(placementOpportunities);
      } catch (error: any) {
        console.error(error);
        alert("Failed to fetch placement opportunities.");
      } finally {
        setLoading(false);
      }
    };

    fetchPlacementOpportunities();
  }, [projectId]);

  if (loading) {
    return (
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">Loading Placement Opportunities...</Typography>
      </Box>
    );
  }

  if (placementOpportunities.length === 0) {
    return (
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">No Placement Opportunities Found.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h5" gutterBottom>
        Placement Opportunities
      </Typography>
      <Grid container spacing={2}>
        {placementOpportunities.map((opportunity) => (
          <Grid item xs={12} key={opportunity._id}>
            <Card>
              <CardContent>
                <Typography variant="h6">
                  {opportunity.opportunityTitle}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  Type: {opportunity.opportunityType}
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  {opportunity.opportunityDescription}
                </Typography>

                <Box sx={{ mt: 2 }}>
                  <Typography variant="body2">
                    <strong>Product Requirements:</strong>{" "}
                    {opportunity.productRequirements}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Placement Location:</strong>{" "}
                    {opportunity.placementLocation}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Duration:</strong> {opportunity.durationOfPlacement}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Visibility Level:</strong>{" "}
                    {opportunity.visibilityLevel}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Compensation Type:</strong>{" "}
                    {opportunity.compensationType}
                    {opportunity.compensationType === "monetary" &&
                      opportunity.compensationAmount && (
                        <>
                          {" "}
                          - ${opportunity.compensationAmount.toLocaleString()}
                        </>
                      )}
                    {opportunity.compensationType === "barter" &&
                      opportunity.barterDetails && (
                        <> - {opportunity.barterDetails}</>
                      )}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Number of Placements Available:</strong>{" "}
                    {opportunity.numberOfPlacements}
                  </Typography>
                  {opportunity.brandBenefits && (
                    <Typography variant="body2">
                      <strong>Brand Benefits:</strong>{" "}
                      {opportunity.brandBenefits}
                    </Typography>
                  )}
                  {opportunity.tags && opportunity.tags.length > 0 && (
                    <Box sx={{ mt: 1 }}>
                      {opportunity.tags.map((tag) => (
                        <Chip label={tag} key={tag} sx={{ mr: 1, mt: 1 }} />
                      ))}
                    </Box>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PlacementOpportunitiesList;
