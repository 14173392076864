import React, { useState } from "react";
import { Container, TextField, Button, Typography } from "@mui/material";
import axios from "../../api";
import { useNavigate } from "react-router-dom";

const ProductForm: React.FC = () => {
  const navigate = useNavigate();

  // Product state
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productImageUrl, setProductImageUrl] = useState("");

  // Submit handler
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const payload = {
      productName,
      productDescription,
      productImageUrl,
    };

    try {
      const response = await axios.post("/products", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const productId = response.data._id;
      navigate(`/products/${productId}`);
    } catch (error: any) {
      console.error("Error adding product:", error);
      alert("An error occurred while adding the product.");
    }
  };

  return (
    <Container maxWidth="md" sx={{ marginTop: 4, marginBottom: 4 }}>
      <Typography variant="h4" gutterBottom>
        Add New Product
      </Typography>
      <form onSubmit={handleSubmit}>
        {/* Product Name */}
        <TextField
          label="Product Name"
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
          required
          fullWidth
          margin="normal"
        />

        {/* Product Description */}
        <TextField
          label="Product Description"
          value={productDescription}
          onChange={(e) => setProductDescription(e.target.value)}
          required
          fullWidth
          multiline
          rows={4}
          margin="normal"
        />

        {/* Product Image URL */}
        <TextField
          label="Product Image URL"
          value={productImageUrl}
          onChange={(e) => setProductImageUrl(e.target.value)}
          fullWidth
          margin="normal"
        />

        {/* Submit Button */}
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          fullWidth
          sx={{ marginTop: 4 }}
        >
          Add Product
        </Button>
      </form>
    </Container>
  );
};

export default ProductForm;
