import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  useMediaQuery,
  Box,
  Badge,
  Menu,
  MenuItem,
  Typography,
  Divider,
  Avatar,
  Button,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Notifications as NotificationsIcon,
  Mail as MailIcon,
  AccountCircle,
  Dashboard as DashboardIcon,
  Logout as LogoutIcon,
} from "@mui/icons-material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useAuthContext } from "../../contexts/AuthContext";
import Logo from "../../assets/images/logo.png";

const Navbar: React.FC = () => {
  const { user } = useAuthContext();

  const theme = useTheme();

  // State for notifications and messages counts
  const [notificationsCount, setNotificationsCount] = useState<number>(0);
  const [messagesCount, setMessagesCount] = useState<number>(0);

  // Fetch notifications and messages counts when component mounts
  useEffect(() => {
    // Simulate fetching counts from an API or context
    // Replace this with actual API calls
    const fetchCounts = async () => {
      try {
        // Example API calls
        // const notificationsResponse = await axios.get('/notifications/count');
        // const messagesResponse = await axios.get('/messages/count');
        // setNotificationsCount(notificationsResponse.data.count);
        // setMessagesCount(messagesResponse.data.count);

        // For demonstration purposes, we'll use static numbers
        setNotificationsCount(5);
        setMessagesCount(2);
      } catch (error) {
        console.error("Error fetching counts:", error);
      }
    };

    if (user) {
      fetchCounts();
    }
  }, [user]);

  return (
    <AppBar position="static" color="primary">
      <Toolbar>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <RouterLink
            to={`/dashboard`}
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <img src={Logo} alt="Logo" style={{ height: "40px" }} />
            <Typography variant="h6" sx={{ marginLeft: 1 }}>
              Media Brand Match
            </Typography>
          </RouterLink>
        </Box>

        <Box sx={{ flexGrow: 1 }} />
        <Typography
          variant="h6"
          component={RouterLink}
          to="/dashboard"
          sx={{ flexGrow: 1, textDecoration: "none", color: "inherit" }}
        >
          Media Brand Match
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        {user ? (
          <>
            {/* Dashboard Icon */}
            <IconButton
              color="inherit"
              component={RouterLink}
              to="/dashboard"
              aria-label="Dashboard"
            >
              <DashboardIcon />
            </IconButton>

            {/* Messages Icon */}
            <IconButton
              color="inherit"
              component={RouterLink}
              to="/messages"
              aria-label="Messages"
            >
              <Badge badgeContent={messagesCount} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton>

            {/* Notifications Icon */}
            <IconButton
              color="inherit"
              component={RouterLink}
              to="/notifications"
              aria-label="Notifications"
            >
              <Badge badgeContent={notificationsCount} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>

            {/* Profile Icon */}
            <IconButton
              edge="end"
              color="inherit"
              aria-label="account of current user"
              aria-controls="profile-menu"
              aria-haspopup="true"
              component={RouterLink}
              to="/profile"
            >
              {user.avatarUrl ? (
                <Avatar alt={user.name} src={user.avatarUrl} />
              ) : (
                <AccountCircle />
              )}
            </IconButton>
          </>
        ) : (
          <>
            <Button color="inherit" component={RouterLink} to="/register">
              Register
            </Button>
            <Button color="inherit" component={RouterLink} to="/login">
              Login
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
