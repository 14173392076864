import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import axios from "../../api";
import { useNavigate, useParams } from "react-router-dom";
import { createProducePlacementOffering } from "../../services/productService";

const matchCriteriaOptions = ["Audience Demographics", "Genre", "Budget Range"];

const PlacementOfferingForm: React.FC = () => {
  const navigate = useNavigate();
  const { productId } = useParams<{ productId: string }>();

  // Placement offering state
  const [offeringTitle, setOfferingTitle] = useState("");
  const [offeringDescription, setOfferingDescription] = useState("");
  const [matchCriteria, setMatchCriteria] = useState("");
  const [otherDetails, setOtherDetails] = useState("");

  // Submit handler
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const payload = {
      offeringTitle,
      offeringDescription,
      matchCriteria,
      otherDetails,
    };

    try {
      if (!productId) return;

      await createProducePlacementOffering(productId, payload);
      navigate(`/products/${productId}`);
    } catch (error: any) {
      console.error("Error adding placement offering:", error);
      alert("An error occurred while adding the placement offering.");
    }
  };

  return (
    <Container maxWidth="md" sx={{ marginTop: 4, marginBottom: 4 }}>
      <Typography variant="h4" gutterBottom>
        Add Placement Offering
      </Typography>
      <form onSubmit={handleSubmit}>
        {/* Offering Title */}
        <TextField
          label="Offering Title"
          value={offeringTitle}
          onChange={(e) => setOfferingTitle(e.target.value)}
          required
          fullWidth
          margin="normal"
        />

        {/* Offering Description */}
        <TextField
          label="Offering Description"
          value={offeringDescription}
          onChange={(e) => setOfferingDescription(e.target.value)}
          required
          fullWidth
          multiline
          rows={3}
          margin="normal"
        />

        {/* Match Criteria */}
        <FormControl fullWidth margin="normal">
          <InputLabel id="matchCriteria-label">Match Criteria</InputLabel>
          <Select
            labelId="matchCriteria-label"
            id="matchCriteria"
            value={matchCriteria}
            onChange={(e) => setMatchCriteria(e.target.value as string)}
            label="Match Criteria"
            required
          >
            {matchCriteriaOptions.map((criteria) => (
              <MenuItem key={criteria} value={criteria}>
                {criteria}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Other Details */}
        <TextField
          label="Other Details"
          value={otherDetails}
          onChange={(e) => setOtherDetails(e.target.value)}
          fullWidth
          multiline
          rows={2}
          margin="normal"
        />

        {/* Submit Button */}
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          fullWidth
          sx={{ marginTop: 4 }}
        >
          Add Placement Offering
        </Button>
      </form>
    </Container>
  );
};

export default PlacementOfferingForm;
