// src/components/Projects/VideoGameFields.tsx
import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Chip,
  Box,
  Typography,
} from "@mui/material";

const platforms = [
  "PC",
  "PlayStation",
  "Xbox",
  "Nintendo Switch",
  "Mobile",
  "VR",
];

const genres = [
  "Action",
  "Platformer",
  "First-Person Shooter (FPS)",
  "Third-Person Shooter",
  "Beat 'em Up",
  "Fighting",
  "Rhythm Action",
  "Adventure",
  "Action-Adventure",
  "Visual Novel",
  "Interactive Movie",
  "Role-Playing (RPG)",
  "Action RPG",
  "JRPG",
  "Western RPG",
  "MMORPG",
  "Tactical RPG",
  "Simulation",
  "Life Simulation",
  "Vehicle Simulation",
  "Construction and Management Simulation",
  "Sports Simulation",
  "Strategy",
  "Real-Time Strategy (RTS)",
  "Turn-Based Strategy (TBS)",
  "MOBA",
  "4X",
  "Puzzle",
  "Logic Puzzle",
  "Match-3",
  "Physics-Based Puzzle",
  "Sports",
  "Team Sports",
  "Individual Sports",
  "Racing",
  "Arcade Racing",
  "Sim Racing",
  "Kart Racing",
  "Horror",
  "Survival Horror",
  "Psychological Horror",
  "Sandbox/Open World",
  "Battle Royale",
  "Survival",
  "Tower Defense",
  "Metroidvania",
  "Stealth",
  "Idle/Incremental",
  "Casual",
  "Twin Stick Shooter",
  "Bullet Hell",
  "Artillery Games",
  "Educational",
  "Card Games/Deck-Building",
  "Point-and-Click",
];
interface VideoGameFieldsProps {
  selectedGenres: string[];
  setSelectedGenres: (genres: string[]) => void;
  platformsSelected: string[];
  setPlatformsSelected: (platforms: string[]) => void;
}

const VideoGameFields: React.FC<VideoGameFieldsProps> = ({
  selectedGenres,
  setSelectedGenres,
  platformsSelected,
  setPlatformsSelected,
}) => {
  return (
    <>
      {/* Genres */}
      <FormControl fullWidth margin="normal">
        <InputLabel id="genres-label">Genres</InputLabel>
        <Select
          labelId="genres-label"
          id="genres"
          multiple
          value={selectedGenres}
          onChange={(e) => setSelectedGenres(e.target.value as string[])}
          input={<OutlinedInput label="Genres" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {(selected as string[]).map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
        >
          {genres.map((genre) => (
            <MenuItem key={genre} value={genre}>
              <Checkbox checked={selectedGenres.indexOf(genre) > -1} />
              <ListItemText primary={genre} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Platforms */}
      <FormControl fullWidth margin="normal">
        <InputLabel id="platforms-label">Release Platforms</InputLabel>
        <Select
          labelId="platforms-label"
          id="platforms"
          multiple
          value={platformsSelected}
          onChange={(e) => setPlatformsSelected(e.target.value as string[])}
          input={<OutlinedInput label="Release Platforms" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {(selected as string[]).map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
        >
          {platforms.map((platform) => (
            <MenuItem key={platform} value={platform}>
              <Checkbox checked={platformsSelected.indexOf(platform) > -1} />
              <ListItemText primary={platform} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default VideoGameFields;
