import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Avatar,
  Grid,
  IconButton,
  Link,
  CircularProgress,
  Button,
} from "@mui/material";
import {
  Facebook,
  Twitter,
  Instagram,
  LinkedIn,
  Language,
} from "@mui/icons-material";
import axios from "../../api";
import { useParams } from "react-router-dom";

interface ProducerProfileData {
  name: string;
  bio?: string;
  website?: string;
  socialMedia?: {
    facebook?: string;
    twitter?: string;
    instagram?: string;
    linkedIn?: string;
  };
  profilePictureUrl?: string;
  genres?: string;
  experienceLevel?: string;
  skills?: string;
}

const ProducerPublicProfile: React.FC = () => {
  const { producerId } = useParams<{ producerId: string }>();
  const [profile, setProfile] = useState<ProducerProfileData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(`/producers/${producerId}`);
        setProfile(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchProfile();
  }, [producerId]);

  if (loading) {
    return (
      <Container sx={{ textAlign: "center", mt: 4 }}>
        <CircularProgress color="secondary" />
      </Container>
    );
  }

  if (!profile) {
    return (
      <Container sx={{ textAlign: "center", mt: 4 }}>
        <Typography variant="h6" color="error">
          Profile not found.
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Box
        sx={{
          p: 3,
          backgroundColor: "background.paper",
          borderRadius: 2,
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Avatar
              alt={profile.name}
              src={profile.profilePictureUrl || "/default-avatar.png"}
              sx={{ width: 100, height: 100 }}
            />
          </Grid>
          <Grid item xs>
            <Typography variant="h4">{profile.name}</Typography>
            {profile.genres && (
              <Typography variant="subtitle1" color="textSecondary">
                Genres: {profile.genres}
              </Typography>
            )}
            {profile.experienceLevel && (
              <Typography variant="body2" color="textSecondary">
                Experience Level: {profile.experienceLevel}
              </Typography>
            )}
          </Grid>
        </Grid>

        <Box sx={{ mt: 3 }}>
          {profile.bio && (
            <>
              <Typography variant="h6">Bio</Typography>
              <Typography variant="body1" sx={{ mt: 1 }}>
                {profile.bio}
              </Typography>
            </>
          )}

          {profile.skills && (
            <>
              <Typography variant="h6" sx={{ mt: 3 }}>
                Skills
              </Typography>
              <Typography variant="body1" sx={{ mt: 1 }}>
                {profile.skills}
              </Typography>
            </>
          )}

          {profile.website && (
            <Box sx={{ mt: 3 }}>
              <Link href={profile.website} target="_blank" rel="noopener">
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<Language />}
                >
                  Visit Website
                </Button>
              </Link>
            </Box>
          )}

          {/* Social Media Links */}
          {profile.socialMedia && (
            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">Connect with me</Typography>
              <Box sx={{ mt: 1 }}>
                {profile.socialMedia.facebook && (
                  <IconButton
                    component="a"
                    href={profile.socialMedia.facebook}
                    target="_blank"
                    rel="noopener"
                  >
                    <Facebook color="primary" />
                  </IconButton>
                )}
                {profile.socialMedia.twitter && (
                  <IconButton
                    component="a"
                    href={profile.socialMedia.twitter}
                    target="_blank"
                    rel="noopener"
                  >
                    <Twitter color="primary" />
                  </IconButton>
                )}
                {profile.socialMedia.instagram && (
                  <IconButton
                    component="a"
                    href={profile.socialMedia.instagram}
                    target="_blank"
                    rel="noopener"
                  >
                    <Instagram color="secondary" />
                  </IconButton>
                )}
                {profile.socialMedia.linkedIn && (
                  <IconButton
                    component="a"
                    href={profile.socialMedia.linkedIn}
                    target="_blank"
                    rel="noopener"
                  >
                    <LinkedIn color="primary" />
                  </IconButton>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default ProducerPublicProfile;
