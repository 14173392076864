// src/components/Dashboard/ProducerDashboard.tsx
import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Avatar,
  CircularProgress,
} from "@mui/material";
import { Movie, VideogameAsset, Notifications } from "@mui/icons-material";
import { useAuthContext } from "../../contexts/AuthContext";
import axios from "../../api";
import { INotification } from "../../types/Notification";

interface Match {
  id: string;
  brandName: string;
  brandLogoUrl: string;
  description: string;
}

interface Project {
  _id: string;
  title: string;
  status: string;
}

const ProducerDashboard: React.FC = () => {
  const [matches, setMatches] = useState<Match[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const { user } = useAuthContext();

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const [matchesRes, projectsRes, notificationsRes] = await Promise.all([
          axios.get("/matches"),
          axios.get("/projects"),
          axios.get("/notifications"),
        ]);

        setMatches(matchesRes.data);
        setProjects(projectsRes.data);
        setNotifications(notificationsRes.data);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  if (!user) {
    return (
      <Container sx={{ textAlign: "center", marginTop: 4 }}>
        <CircularProgress color="secondary" />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ marginTop: 4 }}>
      {/* Welcome Message */}
      <Typography variant="h4" gutterBottom color="textPrimary">
        Welcome back, {user.name}!
      </Typography>

      {/* Projects */}
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ marginTop: 4 }}
      >
        <Typography variant="h5" color="textPrimary">
          <Movie /> Your Projects
        </Typography>
        <Button variant="contained" color="secondary" href="/projects/new">
          Create New Project
        </Button>
      </Grid>
      {loading ? (
        <CircularProgress color="secondary" />
      ) : projects.length > 0 ? (
        <Grid container spacing={2} sx={{ marginTop: 1 }}>
          {projects.map((project) => (
            <Grid item xs={12} sm={6} md={4} key={project._id}>
              <Card sx={{ backgroundColor: "background.paper" }}>
                <CardContent>
                  <Typography variant="h6" color="textPrimary">
                    {project.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Status: {project.status}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    color="secondary"
                    href={`/projects/${project._id}`}
                  >
                    View
                  </Button>
                  <Button
                    size="small"
                    color="secondary"
                    href={`/projects/${project._id}/edit`}
                  >
                    Edit
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography color="textSecondary">No projects found.</Typography>
      )}

      {/* Matches */}
      <Typography
        variant="h5"
        gutterBottom
        color="textPrimary"
        sx={{ marginTop: 4 }}
      >
        <VideogameAsset /> Your Matches
      </Typography>
      {loading ? (
        <CircularProgress color="secondary" />
      ) : matches.length > 0 ? (
        <Grid container spacing={2} sx={{ marginTop: 1 }}>
          {matches.map((match) => (
            <Grid item xs={12} sm={6} md={4} key={match.id}>
              <Card sx={{ backgroundColor: "background.paper" }}>
                <CardContent>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Avatar
                        alt={match.brandName}
                        src={match.brandLogoUrl || "/default-brand-logo.png"}
                        sx={{ width: 60, height: 60 }}
                      />
                    </Grid>
                    <Grid item xs>
                      <Typography variant="h6" color="textPrimary">
                        {match.brandName}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {match.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    color="secondary"
                    href={`/brands/${match.id}`}
                  >
                    View Profile
                  </Button>
                  <Button
                    size="small"
                    color="secondary"
                    href={`/messages/${match.id}`}
                  >
                    Message
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography color="textSecondary">No matches found.</Typography>
      )}
    </Container>
  );
};

export default ProducerDashboard;
