import React from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

interface CommonFieldsProps {
  title: string;
  setTitle: (value: string) => void;
  description: string;
  setDescription: (value: string) => void;
  budget: number | "";
  setBudget: (value: number | "") => void;
  productionCompany: string;
  setProductionCompany: (value: string) => void;
  releaseDate: Date | null;
  setReleaseDate: (date: Date | null) => void;
  projectPoster: File | null;
  setProjectPoster: (file: File | null) => void;
}

const CommonFields: React.FC<CommonFieldsProps> = ({
  title,
  setTitle,
  description,
  setDescription,
  budget,
  setBudget,
  productionCompany,
  setProductionCompany,
  releaseDate,
  setReleaseDate,
  setProjectPoster,
  projectPoster,
}) => {
  return (
    <>
      {/* Title */}
      <TextField
        label="Project Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        required
        fullWidth
        margin="normal"
      />

      {/* Description */}
      <TextField
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        required
        fullWidth
        multiline
        rows={4}
        margin="normal"
      />

      {/* Budget (Optional) */}
      <TextField
        label="Budget (Optional)"
        type="number"
        value={budget}
        onChange={(e) =>
          setBudget(e.target.value === "" ? "" : Number(e.target.value))
        }
        fullWidth
        margin="normal"
      />

      {/* Production Company */}
      <TextField
        label="Production Company"
        value={productionCompany}
        onChange={(e) => setProductionCompany(e.target.value)}
        required
        fullWidth
        margin="normal"
      />

      {/* Release Date */}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Release Date"
          value={releaseDate}
          onChange={(newValue: Date | null) => setReleaseDate(newValue)}
          slotProps={{
            textField: {
              required: true,
              fullWidth: true,
              margin: "normal",
            },
          }}
        />
      </LocalizationProvider>

      {/* Project Poster/Image (Optional) */}
      <Box sx={{ mt: 2, mb: 2 }}>
        <Button variant="contained" component="label">
          Upload Project Poster/Image (Optional)
          <input
            type="file"
            accept="image/*"
            hidden
            onChange={(e) => setProjectPoster(e.target.files?.[0] || null)}
          />
        </Button>
        {projectPoster && (
          <Typography variant="body2" sx={{ mt: 1 }}>
            {projectPoster.name}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default CommonFields;
