import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../api";
import { postPlacementOpportunity } from "../../services/placementOpportunitiesService";
import { TPlacementOpportunityPayload } from "../../types/Project";

const opportunityTypes = [
  "Visual Placement",
  "Verbal Mention",
  "Product Usage",
  "Logo Display",
  "Integrated Storyline",
  "Background Presence",
];

const visibilityLevels = ["High", "Medium", "Low"];

const PlacementOpportunitiesForm: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const navigate = useNavigate();

  // State Management
  const [opportunityTitle, setOpportunityTitle] = useState("");
  const [opportunityType, setOpportunityType] = useState("");
  const [opportunityDescription, setOpportunityDescription] = useState("");
  const [productRequirements, setProductRequirements] = useState("");
  const [durationOfPlacement, setDurationOfPlacement] = useState("");
  const [visibilityLevel, setVisibilityLevel] = useState("");
  const [compensationType, setCompensationType] = useState("");
  const [compensationAmount, setCompensationAmount] = useState<number>(100);
  const [barterDetails, setBarterDetails] = useState("");
  const [numberOfPlacements, setNumberOfPlacements] = useState<number>(1);
  const [brandBenefits, setBrandBenefits] = useState("");

  // Submit Handler
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const payload: TPlacementOpportunityPayload = {
      opportunityTitle,
      opportunityType,
      opportunityDescription,
      productRequirements,
      durationOfPlacement,
      visibilityLevel,
      compensationType,
      numberOfPlacements,
      brandBenefits,
    };

    if (compensationType === "monetary") {
      payload.compensationAmount = compensationAmount;
    } else if (compensationType === "barter") {
      payload.barterDetails = barterDetails;
    }

    try {
      if (!projectId) return;
      await postPlacementOpportunity(projectId, payload);
      navigate(`/projects/${projectId}`);
    } catch (error: any) {
      console.error(error);
      alert("An error occurred while adding the placement opportunity.");
    }
  };

  return (
    <Container maxWidth="md" sx={{ marginTop: 4, marginBottom: 4 }}>
      <Typography variant="h4" gutterBottom>
        Add Placement Opportunity
      </Typography>
      <form onSubmit={handleSubmit}>
        {/* Opportunity Title */}
        <TextField
          label="Opportunity Title"
          value={opportunityTitle}
          onChange={(e) => setOpportunityTitle(e.target.value)}
          required
          fullWidth
          margin="normal"
        />

        {/* Opportunity Type */}
        <FormControl fullWidth margin="normal">
          <InputLabel id="opportunityType-label">Opportunity Type</InputLabel>
          <Select
            labelId="opportunityType-label"
            id="opportunityType"
            value={opportunityType}
            onChange={(e) => setOpportunityType(e.target.value as string)}
            label="Opportunity Type"
            required
          >
            {opportunityTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Opportunity Description */}
        <TextField
          label="Opportunity Description"
          value={opportunityDescription}
          onChange={(e) => setOpportunityDescription(e.target.value)}
          required
          fullWidth
          multiline
          rows={4}
          margin="normal"
        />

        {/* Product Requirements */}
        <TextField
          label="Product Requirements"
          value={productRequirements}
          onChange={(e) => setProductRequirements(e.target.value)}
          required
          fullWidth
          multiline
          rows={3}
          margin="normal"
        />

        {/* Duration of Placement */}
        <TextField
          label="Duration of Placement"
          value={durationOfPlacement}
          onChange={(e) => setDurationOfPlacement(e.target.value)}
          required
          fullWidth
          margin="normal"
          placeholder="e.g., 3 seconds, Entire Episode"
        />

        {/* Visibility Level */}
        <FormControl fullWidth margin="normal">
          <InputLabel id="visibilityLevel-label">Visibility Level</InputLabel>
          <Select
            labelId="visibilityLevel-label"
            id="visibilityLevel"
            value={visibilityLevel}
            onChange={(e) => setVisibilityLevel(e.target.value as string)}
            label="Visibility Level"
            required
          >
            {visibilityLevels.map((level) => (
              <MenuItem key={level} value={level}>
                {level}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Compensation Type */}
        <FormControl fullWidth margin="normal">
          <InputLabel id="compensationType-label">Compensation Type</InputLabel>
          <Select
            labelId="compensationType-label"
            id="compensationType"
            value={compensationType}
            onChange={(e) => setCompensationType(e.target.value as string)}
            label="Compensation Type"
            required
          >
            <MenuItem value="monetary">Monetary</MenuItem>
            <MenuItem value="barter">Barter</MenuItem>
          </Select>
        </FormControl>

        {/* Compensation Amount (Monetary) */}
        {compensationType === "monetary" && (
          <TextField
            label="Monetary Compensation Amount"
            type="number"
            value={compensationAmount}
            onChange={(e) =>
              setCompensationAmount(
                e.target.value === "" ? 100 : Number(e.target.value)
              )
            }
            required
            fullWidth
            margin="normal"
            placeholder="e.g., 5000"
          />
        )}

        {/* Barter Details */}
        {compensationType === "barter" && (
          <TextField
            label="Barter Details"
            value={barterDetails}
            onChange={(e) => setBarterDetails(e.target.value)}
            required
            fullWidth
            multiline
            rows={2}
            margin="normal"
            placeholder="Describe the barter arrangement"
          />
        )}

        {/* Number of Placements Available */}
        <TextField
          label="Number of Placements Available"
          type="number"
          value={numberOfPlacements}
          onChange={(e) =>
            setNumberOfPlacements(
              e.target.value === "" ? 1 : Number(e.target.value)
            )
          }
          required
          fullWidth
          margin="normal"
          placeholder="e.g., 3"
        />

        {/* Brand Benefits (Optional) */}
        <TextField
          label="Additional Brand Benefits (Optional)"
          value={brandBenefits}
          onChange={(e) => setBrandBenefits(e.target.value)}
          fullWidth
          multiline
          rows={3}
          margin="normal"
          placeholder="Advantages or expected outcomes for brands. e.g. social media exposure and mentions"
        />

        {/* Submit Button */}
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          fullWidth
          sx={{ marginTop: 4 }}
        >
          Add Placement Opportunity
        </Button>
      </form>
    </Container>
  );
};

export default PlacementOpportunitiesForm;
