import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";

interface ProjectTypeSelectorProps {
  projectType: string;
  setProjectType: (type: string) => void;
}

const ProjectTypeSelector: React.FC<ProjectTypeSelectorProps> = ({
  projectType,
  setProjectType,
}) => {
  const handleChange = (event: SelectChangeEvent<string>) => {
    setProjectType(event.target.value);
  };

  return (
    <FormControl fullWidth required margin="normal">
      <InputLabel id="project-type-label">Project Type</InputLabel>
      <Select
        labelId="project-type-label"
        id="project-type"
        value={projectType}
        onChange={handleChange}
        label="Project Type"
      >
        <MenuItem value="Video Production">Video Production</MenuItem>
        <MenuItem value="Video Game">Video Game</MenuItem>
      </Select>
    </FormControl>
  );
};

export default ProjectTypeSelector;
