// src/components/Projects/ProjectDetails.tsx
import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  Chip,
  Button,
  Card,
  CardMedia,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import PlacementOpportunitiesList from "../Projects/PlacementOpportunitiesList";
import { getProjectById } from "../../services/projectService";
import { TProject } from "../../types/Project";
import ReactPlayer from "react-player";

const ProjectDetails: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const [project, setProject] = useState<TProject | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProject = async () => {
      try {
        if (!projectId) return;
        const project = await getProjectById(projectId);
        setProject(project);
      } catch (error: any) {
        console.error(error);
        alert("Failed to fetch project details.");
        navigate("/dashboard");
      }
    };

    fetchProject();
  }, [projectId, navigate]);

  if (!project) {
    return (
      <Container maxWidth="md" sx={{ marginTop: 4 }}>
        <Typography variant="h5">Loading...</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ marginTop: 4, marginBottom: 4 }}>
      {/* Project Header */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          {project.title}
        </Typography>
        {project.projectPoster && (
          <Card sx={{ maxWidth: "100%", mb: 2 }}>
            <CardMedia
              component="img"
              height="300"
              image={project.projectPoster}
              alt={`${project.title} Poster`}
            />
          </Card>
        )}
        <Typography variant="body1" gutterBottom>
          {project.description}
        </Typography>

        {/* Trailer Video */}
        {project.projectType === "Video Production" && project.trailerURL && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1">Trailer:</Typography>
            <ReactPlayer url={project.trailerURL} controls width="100%" />
          </Box>
        )}
      </Box>

      {/* Project Details Grid */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">Genres:</Typography>
          {project.genres.map((genre) => (
            <Chip label={genre} key={genre} sx={{ mr: 1, mt: 1 }} />
          ))}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">Budget:</Typography>
          <Typography variant="body2">
            {project.budget ? `$${project.budget.toLocaleString()}` : "N/A"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">Production Company:</Typography>
          <Typography variant="body2">{project.productionCompany}</Typography>
        </Grid>
        {project.projectType === "Video Production" && (
          <>
            {project.shootingLocations &&
              project.shootingLocations.length > 0 && (
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    Shooting Location(s):
                  </Typography>
                  {project.shootingLocations.map((location) => (
                    <Chip
                      label={location}
                      key={location}
                      sx={{ mr: 1, mt: 1 }}
                    />
                  ))}
                </Grid>
              )}
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">Duration:</Typography>
              <Typography variant="body2">{project.duration}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">Format:</Typography>
              <Typography variant="body2">{project.format}</Typography>
            </Grid>
          </>
        )}
      </Grid>

      {/* Add Placement Opportunity Button */}
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 4 }}
        onClick={() =>
          navigate(`/projects/${projectId}/add-placement-opportunity`)
        }
      >
        Add Placement Opportunity
      </Button>

      {/* Placement Opportunities List */}
      <PlacementOpportunitiesList projectId={projectId!} />
    </Container>
  );
};

export default ProjectDetails;
