import React, { useEffect, useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  getProductPlacementOfferingById,
  updateProductPlacementOfferingById,
} from "../../services/productService";

const matchCriteriaOptions = ["Audience Demographics", "Genre", "Budget Range"];

const PlacementOfferingEditForm: React.FC = () => {
  const navigate = useNavigate();
  const { productId, offeringId } = useParams<{
    productId: string;
    offeringId: string;
  }>();

  // Placement offering state
  const [offeringTitle, setOfferingTitle] = useState("");
  const [offeringDescription, setOfferingDescription] = useState("");
  const [matchCriteria, setMatchCriteria] = useState("");
  const [otherDetails, setOtherDetails] = useState("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchPlacementOffering = async () => {
      if (!productId || !offeringId) return;
      try {
        const offering = await getProductPlacementOfferingById(
          productId,
          offeringId
        );
        setOfferingTitle(offering.offeringTitle);
        setOfferingDescription(offering.offeringDescription);
        setMatchCriteria(offering.matchCriteria);
        setOtherDetails(offering.otherDetails || "");
      } catch (error) {
        console.error("Error fetching placement offering:", error);
        alert("An error occurred while fetching the placement offering.");
      } finally {
        setLoading(false);
      }
    };

    fetchPlacementOffering();
  }, [productId, offeringId]);

  // Submit handler
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const payload = {
      offeringTitle,
      offeringDescription,
      matchCriteria,
      otherDetails,
    };

    try {
      if (!productId || !offeringId) return;

      await updateProductPlacementOfferingById(productId, offeringId, payload);
      navigate(`/products/${productId}`);
    } catch (error: any) {
      console.error("Error updating placement offering:", error);
      alert("An error occurred while updating the placement offering.");
    }
  };

  if (loading) {
    return (
      <Container sx={{ textAlign: "center", marginTop: 4 }}>
        <CircularProgress color="secondary" />
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ marginTop: 4, marginBottom: 4 }}>
      <Typography variant="h4" gutterBottom>
        Edit Placement Offering
      </Typography>
      <form onSubmit={handleSubmit}>
        {/* Offering Title */}
        <TextField
          label="Offering Title"
          value={offeringTitle}
          onChange={(e) => setOfferingTitle(e.target.value)}
          required
          fullWidth
          margin="normal"
        />

        {/* Offering Description */}
        <TextField
          label="Offering Description"
          value={offeringDescription}
          onChange={(e) => setOfferingDescription(e.target.value)}
          required
          fullWidth
          multiline
          rows={3}
          margin="normal"
        />

        {/* Match Criteria */}
        <FormControl fullWidth margin="normal">
          <InputLabel id="matchCriteria-label">Match Criteria</InputLabel>
          <Select
            labelId="matchCriteria-label"
            id="matchCriteria"
            value={matchCriteria}
            onChange={(e) => setMatchCriteria(e.target.value as string)}
            label="Match Criteria"
            required
          >
            {matchCriteriaOptions.map((criteria) => (
              <MenuItem key={criteria} value={criteria}>
                {criteria}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Other Details */}
        <TextField
          label="Other Details"
          value={otherDetails}
          onChange={(e) => setOtherDetails(e.target.value)}
          fullWidth
          multiline
          rows={2}
          margin="normal"
        />

        {/* Submit Button */}
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          fullWidth
          sx={{ marginTop: 4 }}
        >
          Update Placement Offering
        </Button>
      </form>
    </Container>
  );
};

export default PlacementOfferingEditForm;
