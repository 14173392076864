import { useAuthContext } from "../../contexts/AuthContext";
import BrandDashboard from "./BrandDashboard";
import ProducerDashboard from "./ProducerDashboard";

const Dashboard: React.FC = () => {
  const authContext = useAuthContext();

  if (!authContext || authContext.loading) {
    return <div>Loading...</div>;
  }

  const { user } = authContext;

  if (!user) {
    return <div>User not authenticated.</div>;
  }

  return user.role === "brand" ? <BrandDashboard /> : <ProducerDashboard />;
};

export default Dashboard;
