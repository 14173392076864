import api from "../api";

export const getProducts = async () => {
  try {
    const response = await api.get("/products");
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw error;
  }
};

export const getProductById = async (productId: string) => {
  try {
    const response = await api.get(`/products/${productId}`);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw error;
  }
};

export const deleteProductById = async (productId: string) => {
  try {
    const response = await api.delete(`/products/${productId}`);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw error;
  }
};

export const createProducePlacementOffering = async (
  productId: string,
  placementOffering: any
) => {
  try {
    const response = await api.post(
      `/products/${productId}/placement-offerings`,
      placementOffering
    );
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw error;
  }
};

export const getProductPlacementOfferings = async (productId: string) => {
  try {
    const response = await api.get(
      `/products/${productId}/placement-offerings`
    );
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw error;
  }
};

export const getProductPlacementOfferingById = async (
  productId: string,
  offeringId: string
) => {
  try {
    const response = await api.get(
      `/products/${productId}/placement-offerings/${offeringId}`
    );
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw error;
  }
};

export const updateProductPlacementOfferingById = async (
  productId: string,
  offeringId: string,
  payload: any
) => {
  try {
    const response = await api.put(
      `/products/${productId}/placement-offerings/${offeringId}`,
      payload
    );
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw error;
  }
};
