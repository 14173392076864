import React, { useEffect, useState } from "react";
import axios from "../../api";
import { Link } from "react-router-dom";

interface Conversation {
  id: string;
  participantName: string;
  lastMessage: string;
}

const NotificationsList: React.FC = () => {
  const [conversations, setConversations] = useState<Conversation[]>([]);

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const response = await axios.get("/messages", {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        setConversations(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchConversations();
  }, []);

  return (
    <div>
      <h2>Messages</h2>
      <ul>
        {conversations.map((conv) => (
          <li key={conv.id}>
            <Link to={`/messages/${conv.id}`}>
              {conv.participantName} - {conv.lastMessage}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NotificationsList;
