import { Box, Chip, TextField } from "@mui/material";
import { useState } from "react";

interface ChipInputProps {
  value: string[];
  onAdd: (chip: string) => void;
  onDelete: (chip: string, index: number) => void;
  placeholder: string;
}

const ChipInput: React.FC<ChipInputProps> = ({
  value,
  onAdd,
  onDelete,
  placeholder,
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      e.preventDefault();
      onAdd(inputValue.trim());
      setInputValue("");
    }
  };

  return (
    <Box>
      <TextField
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        fullWidth
      />
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, mt: 1 }}>
        {value.map((chip, index) => (
          <Chip
            key={`${chip}-${index}`}
            label={chip}
            onDelete={() => onDelete(chip, index)}
            color="primary"
          />
        ))}
      </Box>
    </Box>
  );
};

export default ChipInput;
