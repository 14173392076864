import React from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import { useAuthContext } from "../../contexts/AuthContext";

const RequireAuth: React.FC = () => {
  const authContext = useAuthContext();
  const location = useLocation();

  const { user, loading } = authContext;

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return <Outlet />;
};

export default RequireAuth;
